import {
  Box,
  Text,
} from '@chakra-ui/react';
import { HourlyCourseItem } from './HourlyCourseItem';
import { PcDisplayBlock } from '@/components';
import { Image } from '@/components/Image';
import { LinkButton } from '@/components/Link/LinkButton/LinkButton';
import { getPageInfo } from '@/config/pages';
import {
  CourseCategory,
  ThemeColors
} from '@/features/brand/types/brand';

export type BrandCourseCategoryCardProps = {
  courseCategoryCard: CourseCategory;
  themeColors: ThemeColors;
};

export const BrandCourseCategoryCard = ({
  courseCategoryCard: {
  courseCategoryImageUrl,
  courseCategoryName,
    courseCategoryId,
    courseCategoryDescription,
    courseCategoryDetails,
    annotation
  },
  themeColors: {
    brandColor,
    courseTextColor,
  },
}: BrandCourseCategoryCardProps) => {

  const linkUrl = (courseCategoryId: number) => {
    return getPageInfo(
      'studio/course_category/{courseCategoryId}',
      {
        courseCategoryId: courseCategoryId,
        name: courseCategoryName
      }
    );
  };

  return (
    <Box
      bg={{ lg: 'white' }}
      w={{ base: '100%', lg: '85%' }}
      m={{ lg: 3 }}
      borderRadius={'15px'}
      _focus={{ outline: 'none' }}
    >
      <Box
        display={{ base: 'block', lg: 'flex' }}
      >
        <Box
          mr={{ base: 2, lg: 2 }}
          ml={{ base: 2, lg: 6 }}
          mb={{ base: 0, lg: 15 }}
          flex={1}
          display={{ base: 'flex', lg: 'block' }}
          flexDirection={'column'}
        >
          <Box
            textAlign={'center'}
            pt={{ base: 5, lg: 10 }}
            px={{ base: 3, lg: 5 }}
          >
            <Image
              src={courseCategoryImageUrl}
              alt={courseCategoryName}
              height={300}
              width={450}
              layout={'responsive'}
              objectFit={'cover'}
              objectPosition={'0 10%'}
            />
          </Box>
          <PcDisplayBlock>
            <Text
              color={courseTextColor}
              fontWeight={'bold'}
              fontSize={'24px'}
              textAlign={'left'}
              px={{ base: 3, lg: 5 }}
              pt={3}
              as={'h3'}
            >
              {courseCategoryName}
            </Text>
          </PcDisplayBlock>
          <Text
            textStyle={'h5'}
            lineHeight={'1.5rem'}
            whiteSpace={'pre-line'}
            textAlign={'justify'}
            pt={{ base: 6, lg: 3 }}
            pb={{ lg: 8 }}
            px={{ base: 3, lg: 5 }}
          >
            {courseCategoryDescription}
          </Text>
        </Box>
        {courseCategoryDetails &&
          <Box
            flex={1}
          >
            <Box>
              {courseCategoryDetails.map(({
                time,
                price,
                description,
              }, index) =>
                <HourlyCourseItem
                  time={time}
                  price={price}
                  description={description}
                  courseTextColor={courseTextColor}
                  key={index}
                />
              )}
            </Box>
            <Box
              textAlign={'center'}
              pt={1}
              pb={{ lg: 10 }}
            >
              <LinkButton
                color={brandColor}
                text={'このコースで予約'}
                w={{ base: '80%', lg: '50%' }}
                margin={'0 auto'}
                size={'lg'}
                linkUrl={linkUrl(courseCategoryId)}
                textAlign={'center'}
              />
              {annotation && <Text pt={4}>{annotation}</Text>}
            </Box>
          </Box>}
      </Box>
    </Box>
  );
};
